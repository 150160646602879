import {BasePaginatedListResponse, BasePaginationParams} from './Base';

export enum UserRole {
  SHOWROOM_MANAGER = 'SHOWROOM_MANAGER',
  SALES_MANAGER = 'SALES_MANAGER',
  RECEPTIONIST = 'RECEPTIONIST',
  SELLER = 'SELLER',
  BUYER = 'BUYER',
}

export interface User {
  user_role: UserRole;
  first_name: string;
  last_name: string;
  phone_number: string;
  amq_email: string;
  external_email: string;
}

export interface Account {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  external_email: string;
  role: UserRole;
  client_id: number;
  store_name: string;
  phone_number: string;
  store_id: number;
}

export interface GetUserResponse {
  id: number;
  email: string;
  external_email: string;
  first_name: string;
  last_name: string;
  phone_number: string;
  client_id: string;
  store_name: string;
  amq_manager: number;
  role: UserRole;
}

export interface Manager {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  external_email: string;
  role: UserRole;
  client_id: string;
  store_name: string;
  phone_number: string;
}

export interface GetManagersResponse extends Array<Manager> {}

export interface GetUsersParams extends BasePaginationParams {
  /** Filter users by role */
  roles?: UserRole[];
  /** Filter user by name */
  fullname?: string;

  search?: string;
}

export interface GetUsersResponse extends BasePaginatedListResponse<Account> {}

export interface DeleteMultipleUsersPayload {
  usersToBeDeleted: Array<{
    id: number;
    role: UserRole;
  }>;
}
